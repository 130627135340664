import React from 'react';
import { graphql } from 'gatsby';
import Layout from '@layouts';
import { Banners, SEO, TAIK54 } from '@components';
import Taik54Video from '../popups/Taik54Video';

const { Banner } = Banners;

const Index = ({ data }) => (
  <Layout
    bannerHeight='700px'
    bannerChildren={
      <Banner
        title='TAIK 54'
        description='Discover Africans who are making change in their communities and contributing to positive change on the continent'
      />
    }
  >
    <SEO title='TAIK 54' description='The 54 most inspiring peoples in or from Africa' />
    <TAIK54 data={data} />
    <Taik54Video vidURL='https://youtu.be/Bn4jKQFywFk' />
  </Layout>
);

export const query = graphql`
  {
    allMarkdownRemark(
      filter: { frontmatter: { content_type: { in: ["taik-54-people", "taik-54-organization"] } } }
      sort: { fields: frontmatter___country }
    ) {
      edges {
        node {
          frontmatter {
            content_type
            slug
            date
            name
            country
            image {
              childImageSharp {
                fluid {
                  src
                }
              }
            }
            country_flag {
              childImageSharp {
                fluid {
                  src
                }
              }
            }
            quote
          }
          html
        }
      }
    }
  }
`;

export default Index;
